import CSS from './section-corners.module.css'

export const SectionCorner = ({ position, bottom, backgroundColor }) => {
    return (

        <div 
            className={` 
                ${position == `right` ? CSS.triangleRight : CSS.triangle}
                ${bottom && CSS.bottom}
            `}
            style={{ backgroundColor: backgroundColor}} />
            
    )
}