import Script from "next/script"

import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { ThemeProvider } from 'next-themes'

import { Header } from "../fuselage/theme/2024/global/header/header"
import { Footer } from '../fuselage/theme/2024/global/footer/footer'

import '../css/index.css'

function App({ Component, pageProps, navNodes, globals }) {

	// console.log('globals:', globals)
	// console.log('navNodes:', navNodes)

	return (
		<ThemeProvider themes={['default', 're']} defaultTheme="default" forcedTheme={Component.theme || null}>
			{/* <Script id="google-tag-manager" strategy="afterInteractive">
				{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-MNM37BX');
				`}
			</Script> */}
			<Header mainMenu={navNodes} />
			<Component {...pageProps} />
			<Footer 
				socialConnections={globals[2].socialConnections} 
				contactInfo={globals[2].contactDetails[0] ? globals[2].contactDetails[0].body : null}
				hubspotForm={globals[2].hubspotForm[0] ? globals[2].hubspotForm[0] : null}
			/>
		</ThemeProvider>
	)
}

App.getInitialProps = async () => {

	const { data } = await craftApolloClient().query({
		query: gql`
			query Globals {
				nodes(site: "${process.env.SITE_HANDLE}", navHandle: "${process.env.SITE_HANDLE}MainMenu", level: 1) {
					id
					title
					url
					level
					classes
					newWindow
					children {
						id
						title
						url
						level
						classes
						newWindow
					}
				}
				globalSets(site: "${process.env.SITE_HANDLE}") {
					... on footer_GlobalSet {
						id
						name
						socialConnections {
							... on socialConnections_BlockType {
								id
								hyperlink
								icon
								label
							}
						}
						contactDetails {
							... on contactDetails_BlockType {
								id
								body
							}
						}
						hubspotForm {
							... on hubspotForm_BlockType {
								id
								formId
								portalId
							}
						}
					}
				}
			}
		
		`
	})

	return { 
		navNodes: data.nodes,
		globals: data.globalSets 
	}

}

export default App