import Link from 'next/link'

import ArrowSVG from './img/arrow.svg'

import CSS from './arrow-button.module.css'


export const ArrowButton = ({ href, target, rel, children, large, theme, solid }) => {

    return (
        <a href={ href } 
            
                className={`
                    ${CSS.arrowButton} 
                    ${large ? CSS.lg : ''}
                    ${solid ? CSS.solid : ''}
                `}
                target={target}
                rel={rel}
                style={{ color: theme ? theme.border : 'var(--primary)' }}
            >
                <span className={CSS.arrowContainer}>
                    <ArrowSVG className={CSS.arrow} style={{ stroke: theme ? theme.arrow : 'var(--primary)'}}/>
                </span>

                <span className={CSS.labelContainer} style={{ color: theme ? theme.label : 'var(--dark)' }}>
                    { children }
                </span>

                <span className={CSS.box}></span>
            
        </a>
    )
}